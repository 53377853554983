<template>
  <div>
    <div class="level-item">
      <h1 class="title is-1 page-title">CONFIRM ACCOUNT</h1>
    </div>
    <div class="box b-shdw-3">

      <div v-if="result === 'true'">
        Your account was successfully confirmed, you can now login through the customer hub button.
      </div>
      <div v-else>
        Your account has already been confirmed.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmEmail',
  data() {
    return {
      result: false,
    };
  },
  props: ['id'],
  mounted() {
    this.$http
      .post('customer/create_account_manual_confirm', {
        confirmationCode: this.id,
      })
      .then((result) => {
        this.result = result.data;
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style lang="scss" scoped>
.level-item {
  padding: 20px 0 40px 0;
  text-align: center;
}
</style>
